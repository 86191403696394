import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { MASKS } from 'ng-brazil';
import { DataBaseService } from '../provider.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit{
  @ViewChild("stepper",{static:false}) stepper: MatStepper;
  public MASKS = MASKS;
  empresasCPF:any = [];
  bandeira:any = "";
  codEmpresa:number = null;
  email:any = "";
  cpf:any = "";
  senha:any = "";
  cpfEsqueceuSenha:any = "";
  celularEsqueceuSenha:any = "";
  codigoEsqueceuSenha:number;
  codigo:boolean = false;
  counterInterval:any;
  countdown:any = 59;
  tokenVerify:any = "";
  counterCheck:boolean = false;
  idUser:any = "";
  novaSenha:any = "";
  confirmaNovaSenha:any = "";
  empresaSelecionada:any = [];
  cpfCheck:boolean = false;
  nomeCompleto:any = "";
  estadoCivil:any = "";
  cpfCadastro:any = "";
  nascimento:any = "";
  telefone:any = "";
  celular:any = "";
  whatsapp:boolean = false;
  sexoCadastro:any = "";
  cep:any = "";
  rua:any = "";
  numero:any = "";
  bairro:any = "";
  complemento:any = "";
  municipio:any = "";
  estado:any = "";
  funcao:any = "";
  emailCadastro:any = "";
  senhaCadastro:any = "";
  confirmarSenhaCadastro:any = "";
  novoPlanoEmpresa:boolean = false;
  novoPlanoParticular:boolean = false;
  localTrabalho:any = "";
  matriculaSenha:number = null;
  codigoEmpresaSenha:any = null;
  confirmarMatricula:any = null;
  tipoAssociado:any = "";
  nomeUser:any = "";
  cpfUser:any = "";
  matriculaRecuperacao:number = null;
  userOkey:boolean = false;
  //CADASTRO AUTO//
  subContratoAuto:any = "";
  planoFisicoCheckAuto:boolean = false;
  planoEmpresarialCheckAuto:boolean = false;
  cpfAuto:any = "";
  estadoCivilAuto:any = "";
  nascimentoAuto:any = "";
  nomeAuto:any = "";
  telefoneAuto:any = "";
  celularAuto:any = "";
  ruaAuto:any = "";
  numeroAuto:any = "";
  complementoAuto:any = "";
  bairroAuto:any = "";
  municipioAuto:any = "";
  estadoAuto:any = "";
  emailAuto:any = "";
  localTrabalhoAuto:any = "";
  funcaoAuto:any = "";
  doencaAuto:any = "";
  especificarDoencaAuto:any = "";
  formaPagamentoAuto:any = "";
  mensalidadeAuto:number = 0;
  taxaAdesaoAuto:number = 0;
  diaVencimentoAuto:number = 0;
  mesFolhaPagamentoAuto:any = "";
  whatsappAuto:boolean = false;
  filhoMenorIdadeCheckAuto:boolean = false;
  cepAuto:any = "";
  sexoAuto:any = "";
  senhaAuto:any = "";
  confirmarSenhaAuto:any = "";
  dataAdesaoAuto:any = "";
  planoAuto:any = "";
  totalMensalidadeAuto:number = 0;
  nomeCartaoAuto:any = "";
  numeroCartaoAuto:any = "";
  validadeCartaoAuto:any = "";
  codigoCartaoAuto:any = "";
  bloqueioPagamentosAuto:boolean = false;
  funeralConjugeAuto:any = "";
  funeralExtraAuto:any = "";
  funeralFilhosMaiorAuto:any = "";
  funeralFilhosMenorAuto:any = "";
  editaAdesaoAuto:any = "";
  editaMensalidadeAuto:any = "";
  valorCapitalSeguradoAuto:number = 0;
  valorFuneralConjugeAuto:number = 0;
  valorFuneralExtraAuto:number = 0;
  valorFuneralFilhosMaiorAuto:number = 0;
  valorFuneralFilhosMenorAuto:number = 0;
  totalAsfDependentesAuto:number = 0;
  totalAsfDependentesExtrasAuto:number = 0;
  editaTotalAuto:boolean = false;
  cartaoSelecionadoAuto:any = "";
  public listaDeDependentes:FormArray;
  public listaDeDependentesExtra:FormArray;
  dependentes:FormGroup;
  dependentesExtra:FormGroup;
  get dependentesFormGroup(){return this.dependentes.get('dependente') as FormArray;};
  get dependentesExtraFormGroup(){return this.dependentesExtra.get('dependenteExtra') as FormArray;};
  selectedFiles:any = "";
  files:any = [];
  cartoes:any = [];
  userAssinatura:any = "";
  assinadoAuto:boolean = false;
  empresaProduto:any = "";
  usuarioServidor:boolean = false;
  dadosUsuarioServidor:any = [];
  dependenteServidor:any = "";
  indexServico:any = null;
  indexDependente:any = null;
  constructor(public app: AppComponent, public db:DataBaseService, public auth:AuthService, public _router: Router, public formBuilder: FormBuilder){}
   
  ngOnInit(){
    if(!this.app.isDesktop){
      this.mostrarEsquerda();
    }
    this.dependentes = this.formBuilder.group({
      dependente: this.formBuilder.array([])
    });
    this.dependentesExtra = this.formBuilder.group({
      dependenteExtra: this.formBuilder.array([])
    });
    this.listaDeDependentes = this.dependentes.get('dependente') as FormArray;
    this.listaDeDependentesExtra = this.dependentesExtra.get('dependenteExtra') as FormArray;
    this.db.getConfigs().subscribe((x:any) => {
      this.cartoes = x[1].cartoes;
    });
  }
 
createDependente():FormGroup{
  return this.formBuilder.group({
    tipo:[null, Validators.compose([Validators.required])],
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: [null],
    asf:[null],
    situacao:'ATIVO'
  });
}

addDependente(){
  this.listaDeDependentes.push(this.createDependente());
}

removeDependente(index){
  this.listaDeDependentes.removeAt(index);
  this.selectAsf();
}

tipoDependente(i){
  console.log(this.funeralConjugeAuto)
  let tipoInput = (<HTMLInputElement>document.getElementById('tipo'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asf'+i));
  if(tipoInput == "CÔNJUGE" && this.funeralConjugeAuto == "AUTOMATICO"){
      asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO')
  }else{
    asfInput.value = "";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('')
  }
}

verificaNascimento(i){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipo'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asf'+i));
  let nascimentoInput = (<HTMLInputElement>document.getElementById('nascimento'+i));
  if(tipoInput == "FILHO" || tipoInput == 'FILHA'){
    if(!nascimentoInput.value){
      this.filhoMenorIdadeCheckAuto = false;
      asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
    }else{
      if(!this.verificaIdade(nascimentoInput.value)){
        this.filhoMenorIdadeCheckAuto = true;
        if(this.funeralFilhosMenorAuto == "AUTOMATICO"){
          asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
          this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS');
          asfInput.setAttribute('disabled',"true")
        }
    }else{
     this.filhoMenorIdadeCheckAuto = false;
     asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
     this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
    } 
  }
 }else{
   return
 }
}

createDependenteExtra():FormGroup{
  return this.formBuilder.group({
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: [null],
    tipo:[null, Validators.compose([Validators.required])],
    asf:[null],
    situacao:'ATIVO'
  });
}

addDependenteExtra(){
  this.listaDeDependentesExtra.push(this.createDependenteExtra());
}

removeDependenteExtra(index){
  this.listaDeDependentesExtra.removeAt(index);
  this.selectAsfExtras();
}

selectAsf(){
  this.totalAsfDependentesAuto = 0;
  this.totalMensalidadeAuto = 0;
  let control = 1;
  if(this.dependentes.value.dependente.length > 0){
      this.dependentes.value.dependente.forEach(function(item){
      if(item.asf == "FUNERAL CONTRATADO"){
        this.totalAsfDependentesAuto += this.valorFuneralFilhosMaiorAuto;
      }
        if(control == this.dependentes.value.dependente.length){
          this.totalMensalidadeAuto = this.totalAsfDependentesAuto + this.totalAsfDependentesExtrasAuto + this.mensalidadeAuto;
        }else{
          control++
        }
    }.bind(this))
  }else{
    this.totalMensalidadeAuto = this.totalAsfDependentesAuto + this.totalAsfDependentesExtrasAuto + this.mensalidadeAuto;
  }
}

 selectAsfExtras(){
  this.totalAsfDependentesExtrasAuto = 0;
  this.totalMensalidadeAuto = 0;
  let control = 1;
  if(this.dependentesExtra.value.dependenteExtra.length > 0){
    this.dependentesExtra.value.dependenteExtra.forEach(function(item){
      if(this.funeralExtraAuto == "SIM" && item.asf == "FUNERAL CONTRATADO"){
        this.totalAsfDependentesExtrasAuto += this.valorFuneralExtraAuto;
      }
      if(control == this.dependentesExtra.value.dependenteExtra.length){
        this.totalMensalidadeAuto = this.totalAsfDependentesAuto + this.totalAsfDependentesExtrasAuto + this.mensalidadeAuto;
      }else{
        control++
      }
    }.bind(this))
  }else{
    this.totalMensalidadeAuto = this.totalAsfDependentesAuto + this.totalAsfDependentesExtrasAuto + this.mensalidadeAuto
  }
}

verificaIdade(e){
  if(e){
    let d = new Date;
    let dt = e.split('/').reverse().join('-');
    let data = new Date(dt.split('-')[0],dt.split('-')[1]-1,dt.split('-')[2])
    let ano_atual = d.getFullYear();
    let mes_atual = d.getMonth() + 1;
    let dia_atual = d.getDate();
    let ano_aniversario = data.getFullYear();;
    let mes_aniversario = data.getMonth() + 1;
    let dia_aniversario = data.getDate();
    let idade = ano_atual - ano_aniversario;
    if(mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario){
        idade--;
    }
    if(idade >= 18){
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }
}

buscarCepAuto(cepGet){
  let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
  if(c.length > 7){
    this.db.getCep(c).subscribe((data:any) => {
      let cep = data;
       if(cep.erro == true){
         alert("CEP NAO ENCONTRADO");
       }else{
         this.ruaAuto = cep.logradouro.toUpperCase();
         this.bairroAuto = cep.bairro.toUpperCase();
         this.municipioAuto = cep.localidade.toUpperCase();
         this.estadoAuto = cep.uf.toUpperCase();
       }
    });
  }
}

selectPlano(plano){
    this.mensalidadeAuto = plano?.mensalidade;
    this.funeralConjugeAuto = plano?.funeralConjuge;
    this.funeralExtraAuto = plano?.funeralExtra;
    this.funeralFilhosMaiorAuto = plano?.funeralFilhosMaior;
    this.funeralFilhosMenorAuto = plano?.funeralFilhosMenor;
    this.editaAdesaoAuto = plano?.editaAdesao;
    this.editaMensalidadeAuto = plano?.editaMensalidade;
    this.editaTotalAuto = plano?.editaTotal;
    if(plano?.adesao == null){
      this.taxaAdesaoAuto = 0;
    }else{
      this.taxaAdesaoAuto = plano?.adesao;
    }
    if(plano?.valorCapitalSegurado == null){
      this.valorCapitalSeguradoAuto = 0;
    }else{
      this.valorCapitalSeguradoAuto = plano?.valorCapitalSegurado;
    }
    if(plano?.valorFuneralConjuge == null){
      this.valorFuneralConjugeAuto = 0;
    }else{
      this.valorFuneralConjugeAuto = plano?.valorFuneralConjuge;
    }
    if(plano?.valorFuneralExtra == null){
      this.valorFuneralExtraAuto = 0;
    }else{
      this.valorFuneralExtraAuto = plano?.valorFuneralExtra;
    }
    if(plano?.valorFuneralFilhosMaior == null){
      this.valorFuneralFilhosMaiorAuto = 0;
    }else{
      this.valorFuneralFilhosMaiorAuto = plano?.valorFuneralFilhosMaior;
    }
    if(plano?.valorFuneralFilhosMenor == null){
      this.valorFuneralFilhosMenorAuto = 0;
    }else{
      this.valorFuneralFilhosMenorAuto =  plano?.valorFuneralFilhosMenor;
    }
    this.totalMensalidadeAuto = this.totalAsfDependentesAuto + this.totalAsfDependentesExtrasAuto + this.mensalidadeAuto;
    this.avancaStep();
}

cartaoSelect(e,index){
  let checked = e.srcElement.checked;
  if(this.cartaoSelecionadoAuto != ""){
   let checks = document.getElementsByName('ch');
    for(let i = 0; i < checks.length; i++){
      let c = checks[i] as HTMLInputElement;
      c.checked= false
      if(i+1 == checks.length){
        if(checked){
          let cb = checks[index] as HTMLInputElement;
          cb.checked= true
          this.cartaoSelecionadoAuto = e.srcElement.labels[0].innerText;
        }else{
          this.cartaoSelecionadoAuto = "";
        }
      }
    }
  }else{
    this.cartaoSelecionadoAuto = e.srcElement.labels[0].innerText;
  }
}

selectDoenca(e){
  let inner = e.srcElement.labels[0].innerText;
  let checked = e.srcElement.checked;
  let c;
  if(inner == "SIM" && checked == true){
      this.doencaAuto = inner;
      c = <HTMLInputElement>document.getElementById('doencaNaoAuto');
      c.checked = false
  }
  if(inner == "NÃO" && checked == true){
      this.doencaAuto = inner;
      c = <HTMLInputElement>document.getElementById('doencaSimAuto');
      c.checked = false
  }
}

onChangeClick(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

onChangeCadastro(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

  selectPlanoEmpresa(){
    this.novoPlanoEmpresa = true;
    this.novoPlanoParticular = false;
  }

  selectPlanoParticular(){
    this.novoPlanoEmpresa = false;
    this.novoPlanoParticular = true;
  }

  formatarCampo(){
    if (this.cpf.length <= 11) {
        this.cpf = this.mascaraCpf();
    } else {
        this.cpf = this.mascaraCnpj();
    }
  }

  retirarFormatacao(){
      this.cpf = this.cpf.replace(/(\.|\/|\-)/g,"");
  }

  mascaraCpf(){
      return this.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
  }

  mascaraCnpj(){
      return this.cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
  }

   logger(){
    if(this.cpf.length < 15){
     this.login(this.cpf,this.senha);
    }else{
      this.auth.loginEmpresa(this.cpf,this.senha)
    }
  }
  
  verificarUsuario(){
    if(this.cpf.length < 15){
        this.db.getLoginCpf(this.cpf).subscribe((data:any) => {
          if(data.length == 1){
            this.usuarioServidor = false;
            this.matriculaSenha = data[0].matricula;
            this.idUser = data[0]._id;
            this.empresaProduto = data[0].empresa;
            if(this.cpf == data[0].cpf){
              this.tipoAssociado = "Titular";
              this.nomeUser = data[0].nome;
              this.cpfUser = data[0].cpf;
              if(data[0].senha){
                this.userOkey = true;
              }else{
                this.openCadastrarSenha();
              }
            }else{
              let iDep = data[0].dependentes.map((dep) => {
                return dep.cpf;
              }).indexOf(this.cpf);
              if(iDep >= 0){
                this.tipoAssociado = "Dependente";
                this.nomeUser = data[0].dependentes[iDep].nome;
                this.cpfUser = data[0].dependentes[iDep].cpf;
                if(data[0].dependentes[iDep].senha){
                  this.userOkey = true;
                  }else{
                    this.openCadastrarSenha();
                  }
              }else{
                let iDepExtra = data[0].dependentesExtra.map((depExtra) => {
                  return depExtra.cpf;
                }).indexOf(this.cpf);
                if(iDepExtra >= 0){
                  this.tipoAssociado = "Dependente Extra";
                  this.nomeUser = data[0].dependentesExtra[iDepExtra].nome;
                  this.cpfUser = data[0].dependentesExtra[iDepExtra].cpf;
                  if(data[0].dependentesExtra[iDepExtra].senha){
                    this.userOkey = true;
                    }else{
                      this.openCadastrarSenha();
                    }
                }else{
                  console.warn("NEM DEVIA TER PUXADO")
                }
              }
            }
          }else if(data.length > 1){
            this.usuarioServidor = false;
            for(let i:any = 0; i < data.length; i++){
              this.empresasCPF.push(data[i])
            }
            let modEscolheEmpresa:any = document.getElementById('escolheEmpresa');
            modEscolheEmpresa.style.display = "flex";
          }else{
            this.db.getServidorLogin(this.cpf).subscribe((dataS:any) => {
              if(dataS){
                this.idUser = dataS[0]._id;
                this.usuarioServidor = true;
                this.dadosUsuarioServidor = dataS[0];
                if(this.cpf == dataS[0].cpf){
                  this.tipoAssociado = "Titular";
                  this.nomeUser = dataS[0].nome;
                  this.cpfUser = dataS[0].cpf;
                  if(dataS[0].servicos.length > 0){
                    let servicosContratados = [];
                    for(let x:any = 0; x < dataS[0].servicos.length;x++){
                      servicosContratados.push(dataS[0].servicos[x].servico)
                      if(dataS[0].servicos.length - 1 == x){
                        if(servicosContratados.length > 1){
                          this.empresaProduto = "VIVA MAX";
                          this.app.hibrido = true;
                        }else{
                          if(servicosContratados.includes("TELEMEDICINA")){
                            this.empresaProduto = "CONECTA";
                            this.app.hibrido = false;
                          }else  if(servicosContratados.includes("CONVÊNIO DE SAÚDE")){
                            this.empresaProduto = "VIVA MAX";
                            this.app.hibrido = false;
                          }
                        }
                        if(dataS[0].senha){
                          this.userOkey = true;
                        }else{
                          this.openCadastrarSenha();
                        }
                      }
                    }
                  }else{
                    this.empresaProduto = "SEGUROS";
                    this.app.hibrido = false;
                    if(dataS[0].senha){
                      this.userOkey = true;
                    }else{
                      this.openCadastrarSenha();
                    }
                  }
                }else{
                  let iDep = dataS[0].dependentes.map((dep) => {
                    return dep.cpf;
                  }).indexOf(this.cpf);
                  if(iDep >= 0){
                    this.tipoAssociado = "Dependente";
                    this.nomeUser = dataS[0].dependentes[iDep].nome;
                    this.cpfUser = dataS[0].dependentes[iDep].cpf;
                    if(dataS[0].dependentes[iDep].senha){
                      this.userOkey = true;
                      }else{
                        this.openCadastrarSenha();
                      }
                   }else{
                      if(dataS[0].servicos.length > 0){
                        let servicosContratados = [];
                        for(let x:any = 0; x < dataS[0].servicos.length;x++){
                          let iDepServ:any = dataS[0].servicos[x].dependentes.map((d:any) =>{
                            return d.cpf
                          }).indexOf(this.cpf);
                          if(iDepServ >= 0){
                            servicosContratados.push(dataS[0].servicos[x].servico);
                            this.dependenteServidor = dataS[0].servicos[x].dependentes[iDepServ];
                            if(dataS[0].servicos[x].dependentes[iDepServ].senha){
                              this.userOkey = true;
                            }else{
                              this.indexDependente = iDepServ;
                              this.indexServico = x;
                            }
                          }
                          if(dataS[0].servicos.length - 1 == x){
                              this.tipoAssociado = "Dependente Servidor";
                              this.nomeUser = dataS[0].servicos[x].dependentes[iDepServ].nomeCompleto;
                              this.cpfUser = this.cpf;
                            if(!this.userOkey){
                              this.openCadastrarSenha();
                            }
                          }
                        }
                      }
                  }
                }
              }else{
            alert("CPF Não Cadastrado! Entre em contato com a empresa!")
            }
          })
          }
        })
      }else{
        this.db.getLoginCnpj(this.cpf).subscribe((data:any) => {
          if(data.length > 0){
            if(data[0].ultimoAcesso){
              this.userOkey = true;
            }else{
              this.openCadastrarSenhaCnpj();
            }
          }else{
            alert("CNPJ Não Cadastrado!")
          }
        })
      }
  }

  selectEmpresaCPF(cliente:any){
    this.closeModalEscolheEmpresa();
    this.empresaProduto = cliente.empresa;
    this.matriculaSenha = cliente.matricula;
    this.idUser = cliente._id;
    this.app.tipoEmpresa = cliente.empresa;
    if(this.cpf == cliente.cpf){
      this.tipoAssociado = "Titular";
      this.nomeUser = cliente.nome;
      this.cpfUser = cliente.cpf;
      if(cliente.senha){
        this.userOkey = true;
      }else{
        this.openCadastrarSenha();
      }
    }else{
      let iDep = cliente.dependentes.map((dep) => {
        return dep.cpf;
      }).indexOf(this.cpf);
      if(iDep >= 0){
        this.tipoAssociado = "Dependente";
        this.nomeUser = cliente.dependentes[iDep].nome;
        this.cpfUser = cliente.dependentes[iDep].cpf;
        if(cliente.dependentes[iDep].senha){
          this.userOkey = true;
          }else{
            this.openCadastrarSenha();
          }
      }else{
        let iDepExtra = cliente.dependentesExtra.map((depExtra) => {
          return depExtra.cpf;
        }).indexOf(this.cpf);
        if(iDepExtra >= 0){
          this.tipoAssociado = "Dependente Extra";
          this.nomeUser = cliente.dependentesExtra[iDepExtra].nome;
          this.cpfUser = cliente.dependentesExtra[iDepExtra].cpf;
          if(cliente.dependentesExtra[iDepExtra].senha){
            this.userOkey = true;
            }else{
              this.openCadastrarSenha();
            }
        }else{
          console.warn("NEM DEVIA TER PUXADO")
        }
      }
    }
  }

  closeModalEscolheEmpresa(){
    let modEscolheEmpresa:any = document.getElementById('escolheEmpresa');
    modEscolheEmpresa.style.display = "none";
    this.empresasCPF = [];
  }
  
  resetSenha(){
    this.userOkey = false;
    this.cpf = "";
    this.senha = "";
  }
  
 login(cpf, pwd){
    let storage = Math.random().toString(36).slice(-8);
    this.db.getLoginByUsuario(cpf,this.empresaProduto).subscribe((data:any) => {
      if(data.length > 0){
        this.idUser = data[0]._id;
        if(data[0].cpf == cpf){
          this.nomeUser = data[0].nome;
          this.cpfUser = cpf;
          if(data[0].senha){
            if(data[0].senha == pwd){
              localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
              localStorage.setItem('idUserVM', data[0]._id);
              localStorage.setItem('idServidor', '');
              localStorage.setItem('nomeUsuarioVM', data[0].nome);
              localStorage.setItem('tipoUsuarioVM', 'Titular');
              localStorage.setItem('numSorte', data[0].numSorte);
              localStorage.setItem('cpfVM', this.cpfUser);
              this.db.nomeUsuario = data[0].nome;
              this._router.navigate(['/inicio']);
            }else{
              alert("Senha Incorreta!");
            }
          }else{
            this.tipoAssociado = "Titular";
            this.openCadastrarSenha();
          }
        }else{
          if(data[0].dependentes && data[0].dependentes.length > 0){
            let i = data[0].dependentes.map((dep) => {
              return dep.cpf;
            }).indexOf(cpf);
            if(i >= 0){
              this.nomeUser = data[0].dependentes[i].nome;
              this.cpfUser = data[0].dependentes[i].cpf;
              if(data[0].dependentes[i].senha){
                if(data[0].dependentes[i].senha == pwd){
                  localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
                  localStorage.setItem('idUserVM', data[0]._id);
                  localStorage.setItem('nomeUsuarioVM', data[0].dependentes[i].nome);
                  localStorage.setItem('tipoUsuarioVM', 'Dependente');
                  localStorage.setItem('numSorte', data[0].dependentes[i].numSorte);
                  localStorage.setItem('cpfVM', this.cpfUser);
                  this.db.nomeUsuario = data[0].dependentes[i].nome;
                  this._router.navigate(['/inicio']);
                }else{
                  alert("Senha Incorreta!");
                }
              }else{
                this.tipoAssociado = "Dependente";
                this.openCadastrarSenha();
              }
            }else{
              if(data[0].dependentesExtra && data[0].dependentesExtra.length > 0){
                let index = data[0].dependentesExtra.map((depExtras) => {
                  return depExtras.cpf;
                }).indexOf(cpf);
                if(index >= 0){
                  this.nomeUser = data[0].dependentesExtra[index].nome;
                  this.cpfUser = data[0].dependentesExtra[index].cpf;
                  if(data[0].dependentesExtra[index].senha){
                    if(data[0].dependentesExtra[index].senha == pwd){
                      localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
                      localStorage.setItem('idUserVM', data[0]._id);
                      localStorage.setItem('nomeUsuarioVM', data[0].dependentesExtra[index].nome);
                      localStorage.setItem('tipoUsuarioVM', 'Dependente Extra');
                      localStorage.setItem('numSorte', data[0].dependentesExtra[index].numSorte);
                      localStorage.setItem('cpfVM', this.cpfUser);
                      this.db.nomeUsuario = data[0].dependentesExtra[index].nome;
                      this._router.navigate(['/inicio']);
                    }else{
                      alert("Senha Incorreta!");
                    }
                  }else{
                    this.tipoAssociado = "Dependente Extra";
                    this.openCadastrarSenha();
                  }
                }else{
                  alert("CPF não encontrado, verifique e tente novamente!")
                }
              }else{
                alert("CPF não encontrado, verifique e tente novamente!")
              }
            }
          }else{
            if(data[0].dependentesExtra && data[0].dependentesExtra.length > 0){
              let index = data[0].dependentesExtra.map((depExtras) => {
                return depExtras.cpf;
              }).indexOf(cpf);
              if(index >= 0){
                this.nomeUser = data[0].dependentesExtra[index].nome;
                this.cpfUser = data[0].dependentesExtra[index].cpf;
                if(data[0].dependentesExtra[index].senha){
                  if(data[0].dependentesExtra[index].senha == pwd){
                    localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
                    localStorage.setItem('idUserVM', data[0]._id);
                    localStorage.setItem('nomeUsuarioVM', data[0].dependentesExtra[index].nome);
                    localStorage.setItem('tipoUsuarioVM', 'Dependente Extra');
                    localStorage.setItem('numSorte', data[0].dependentesExtra[index].numSorte);
                    localStorage.setItem('cpfVM', this.cpfUser);
                    this.db.nomeUsuario = data[0].dependentesExtra[index].nome;
                    this._router.navigate(['/inicio']);
                  }else{
                    alert("Senha Incorreta!");
                  }
                }else{
                  this.tipoAssociado = "Dependente Extra";
                  this.openCadastrarSenha();
                }
              }else{
                alert("CPF não encontrado, verifique e tente novamente!")
              }
            }else{
              alert("CPF não encontrado, verifique e tente novamente!")
            }
          }
        }
      }else{
         this.db.getServidorLogin(this.cpf).subscribe((dataS:any) => {
          if(dataS){
            this.idUser = dataS[0]._id;
            this.usuarioServidor = true;
            this.dadosUsuarioServidor = dataS[0];
            if(dataS[0].cpf == this.cpf){
              if(dataS[0].senha){
                if(dataS[0].senha == pwd){
                  this.tipoAssociado = "Titular";
                  localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
                  localStorage.setItem('idServidor', dataS[0]._id);
                  localStorage.setItem('idUserVM', '');
                  localStorage.setItem('nomeUsuarioVM', dataS[0].nome);
                  localStorage.setItem('tipoUsuarioVM', 'Titular');
                  localStorage.setItem('numSorte', dataS[0].numSorte);
                  localStorage.setItem('cpfVM', this.cpfUser);
                  this.db.nomeUsuario = dataS[0].nome;
                  this.db.idServidor = dataS[0]._id;
                  this.db.idUsuario = "";
                  this.nomeUser = dataS[0].nome;
                  this.cpfUser = dataS[0].cpf;
                  if(dataS[0].servicos.length > 0){
                    this.db.servicosContratados = [];
                    for(let x:any = 0; x < dataS[0].servicos.length;x++){
                      this.db.servicosContratados.push(dataS[0].servicos[x].servico)
                        if(dataS[0].servicos.length - 1 == x){
                        this._router.navigate(['/servidor']);
                        }
                      }
                    }
                  }else{
                    alert("Senha Incorreta!");
                  }
                }else{
                  this.tipoAssociado = "Titular";
                  this.openCadastrarSenha();
                  }
            }else{
              let iDep = dataS[0].dependentes.map((dep) => {
                return dep.cpf;
              }).indexOf(this.cpf);
              if(iDep >= 0){
                localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
                localStorage.setItem('idServidor', dataS[0]._id);
                localStorage.setItem('idUserVM', '');
                localStorage.setItem('nomeUsuarioVM', dataS[0].dependentes[iDep].nome);
                localStorage.setItem('tipoUsuarioVM', 'Dependente');
                localStorage.setItem('numSorte', dataS[0].numSorte);
                localStorage.setItem('cpfVM', this.cpfUser);
                this.db.nomeUsuario = dataS[0].dependentes[iDep].nome;
                this.db.idServidor = dataS[0]._id;
                this.db.idUsuario = "";
                this.nomeUser = dataS[0].dependentes[iDep].nome;
                this.cpfUser = dataS[0].dependentes[iDep].cpf;
                this.tipoAssociado = "Dependente";
                if(dataS[0].dependentes[iDep].senha){
                  this.userOkey = true;
                  }else{
                    this.openCadastrarSenha();
                  }
              }else{
                  if(dataS[0].servicos.length > 0){
                    let servicosContratados = [];
                    for(let x:any = 0; x < dataS[0].servicos.length;x++){
                      let iDepServ:any = dataS[0].servicos[x].dependentes.map((d:any) =>{
                        return d.cpf
                      }).indexOf(this.cpf);
                      if(iDepServ >= 0){
                        this.tipoAssociado = "Dependente Servidor";
                        localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
                        localStorage.setItem('idServidor', dataS[0]._id);
                        localStorage.setItem('idUserVM', '');
                        localStorage.setItem('nomeUsuarioVM', dataS[0].servicos[x].dependentes[iDepServ].nomeCompleto);
                        localStorage.setItem('tipoUsuarioVM', 'Dependente');
                        localStorage.setItem('numSorte', dataS[0].numSorte);
                        localStorage.setItem('cpfVM', this.cpfUser);
                        this.db.nomeUsuario = dataS[0].servicos[x].dependentes[iDepServ].nomeCompleto;
                        this.db.idServidor = dataS[0]._id;
                        this.db.idUsuario = "";
                        this.nomeUser = dataS[0].servicos[x].dependentes[iDepServ].nomeCompleto;
                        this.cpfUser = dataS[0].servicos[x].dependentes[iDepServ].cpf;
                        servicosContratados.push(dataS[0].servicos[x].servico)
                        if(dataS[0].servicos[x].dependentes[iDepServ].senha){
                          this.userOkey = true;
                        }else{
                          this.indexServico = x;
                          this.indexDependente = iDepServ;
                        }
                      }
                      if(dataS[0].servicos.length - 1 == x){
                          this.db.servicosContratados = servicosContratados;
                       if(!this.userOkey){
                          this.openCadastrarSenha();
                        }else{
                          this._router.navigate(['/servidor']);
                        }
                      }
                    }
                  }else{
                    alert("CPF Não Cadastrado! Entre em contato com a empresa!")
                  }
              }
            }          
          }else{
            alert("CPF Não Cadastrado! Entre em contato com a empresa!")
          }
        })        
      }
    })
  }

  mostrarEsquerda(){
    let leftCol = document.getElementById('leftCol');
    let rigthCol = document.getElementById('rightCol');
    rigthCol.style.display = 'none';
    leftCol.style.display = 'block';
    leftCol.style.width = '100%';
  }

  mostrarDireita(){
    let leftCol = document.getElementById('leftCol');
    let rigthCol = document.getElementById('rightCol');
    leftCol.style.display = 'none';
    rigthCol.style.display = 'block';
    rigthCol.style.width = '100%';
  }

  openEsqueceuSenha(){
    if(!this.app.isDesktop){
      this.mostrarDireita();
    }
    let modImg = document.getElementById('logo');
    modImg.style.display = 'none';
    let leftCol = document.getElementById('containerLog');
    leftCol.style.display = 'none';
    let logoLogin = document.getElementById('logoLogin');
    logoLogin.style.display = 'block';
    let mod = document.getElementById('esqueceuSenhaDados');
    mod.style.display = 'block';
  }
  
  closeEsqueceuSenha(){
    if(!this.app.isDesktop){
      this.mostrarEsquerda();
    }
    let modImg = document.getElementById('logo');
    modImg.style.display = 'flex';
    let leftCol = document.getElementById('containerLog');
    leftCol.style.display = 'block';
    let logoLogin = document.getElementById('logoLogin');
    logoLogin.style.display = 'none';
    let mod = document.getElementById('esqueceuSenhaDados');
    mod.style.display = 'none';
    this.cpfEsqueceuSenha = "";
    this.celularEsqueceuSenha = "";
    this.codigoEsqueceuSenha = null;
    this.tokenVerify = "";
    this.idUser = "";
    this.novaSenha = "";
    this.confirmaNovaSenha = "";
    this.matriculaRecuperacao = null;
  }

  openCadastrarSenha(){
    if(!this.app.isDesktop){
      this.mostrarDireita();
    }
    let modImg = document.getElementById('logo');
    modImg.style.display = 'none';
    let leftCol = document.getElementById('containerLog');
    leftCol.style.display = 'none';
    let logoLogin = document.getElementById('logoLogin');
    logoLogin.style.display = 'block';
    let mod = document.getElementById('cadastroSenha');
    mod.style.display = 'block';
  }

  closeCadastrarSenha(){
    if(!this.app.isDesktop){
      this.mostrarEsquerda();
    }
    let modImg = document.getElementById('logo');
    modImg.style.display = 'flex';
    let leftCol = document.getElementById('containerLog');
    leftCol.style.display = 'block';
    let logoLogin = document.getElementById('logoLogin');
    logoLogin.style.display = 'none';
    let mod = document.getElementById('cadastroSenha');
    mod.style.display = 'none';
    this.matriculaSenha = null;
    this.confirmarMatricula = null;
    this.novaSenha = "";
    this.confirmaNovaSenha = "";
    this.matriculaRecuperacao = null;
  }
  
  openCadastrarSenhaCnpj(){
    if(!this.app.isDesktop){
      this.mostrarDireita();
    }
    let modImg = document.getElementById('logo');
    modImg.style.display = 'none';
    let leftCol = document.getElementById('containerLog');
    leftCol.style.display = 'none';
    let logoLogin = document.getElementById('logoLogin');
    logoLogin.style.display = 'block';
    let mod = document.getElementById('cadastroSenhaCnpj');
    mod.style.display = 'block';
  }

  closeCadastrarSenhaCnpj(){
    if(!this.app.isDesktop){
      this.mostrarEsquerda();
    }
    let modImg = document.getElementById('logo');
    modImg.style.display = 'flex';
    let leftCol = document.getElementById('containerLog');
    leftCol.style.display = 'block';
    let logoLogin = document.getElementById('logoLogin');
    logoLogin.style.display = 'none';
    let mod = document.getElementById('cadastroSenhaCnpj');
    mod.style.display = 'none';
    this.codigoEmpresaSenha = null;
    this.confirmarMatricula = null;
    this.novaSenha = "";
    this.confirmaNovaSenha = "";
    this.matriculaRecuperacao = null;
  }

  esqueceuSenha(){
    if(this.codigoEsqueceuSenha == this.tokenVerify){
      let modSenha = document.getElementById('esqueceuSenhaDados');
      modSenha.style.display = 'none';
      let modNovaSenha = document.getElementById('cadastroSenha');
      modNovaSenha.style.display = 'block';
      this.confirmarMatricula = this.matriculaSenha;
    }else{
      alert("Código Incorreto!")
    }
  }

  solicitarCodigo(){
    let codigo = Number(Math.random().toString().split('.')[1].slice(1,7));
    this.tokenVerify = codigo;
    let number = this.celularEsqueceuSenha.replace(/_/gi,'').replace('(','').replace(')','').replace(' ','').replace('-','');
    this.db.getAssociadosRecuperarSenha(this.cpfEsqueceuSenha,this.matriculaRecuperacao).subscribe((data:any) => {
      if(data.length > 0){
        this.idUser = data[0]._id;
        if(data[0].cpf == this.cpfEsqueceuSenha){
            this.tipoAssociado = "Titular";
            this.nomeUser = data[0].nome;
            this.cpfUser = data[0].cpf;
            this.db.envioSMSRecuperacao(number, codigo).subscribe(res => {
              console.log(res);
              this.counter();  
              this.codigo = true;
            });
        }else{
          if(data[0].dependentes && data[0].dependentes.length > 0){
            let i = data[0].dependentes.map((dep) => {
              return dep.cpf;
            }).indexOf(this.cpfEsqueceuSenha);
            if(i >= 0){
                this.tipoAssociado = "Dependente";
                this.nomeUser = data[0].dependentes[i].nome;
                this.cpfUser = data[0].dependentes[i].cpf;
                this.db.envioSMSRecuperacao(number, codigo).subscribe(res => {
                  console.log(res);
                  this.counter();  
                  this.codigo = true;
                });
            }else{
              if(data[0].dependentesExtra && data[0].dependentesExtra.length > 0){
                let index = data[0].dependentesExtra.map((depExtras) => {
                  return depExtras.cpf;
                }).indexOf(this.cpfEsqueceuSenha);
                if(index >= 0){
                    this.tipoAssociado = "Dependente Extra";
                    this.nomeUser = data[0].dependentesExtra[index].nome;
                    this.cpfUser = data[0].dependentesExtra[index].cpf;
                    this.db.envioSMSRecuperacao(number, codigo).subscribe(res => {
                      console.log(res);
                      this.counter();  
                      this.codigo = true;
                    });
                }else{
                  alert("Não encontramos esse CPF vinculado a essa Matricula, verifique e tente novamente!")
                }
              }else{
                alert("Não encontramos esse CPF vinculado a essa Matricula, verifique e tente novamente!")
              }
            }
          }else{
            if(data[0].dependentesExtra && data[0].dependentesExtra.length > 0){
              let index = data[0].dependentesExtra.map((depExtras) => {
                return depExtras.cpf;
              }).indexOf(this.cpfEsqueceuSenha);
              if(index >= 0){
                  this.tipoAssociado = "Dependente Extra";
                  this.nomeUser = data[0].dependentesExtra[index].nome;
                  this.cpfUser = data[0].dependentesExtra[index].cpf;
                  this.db.envioSMSRecuperacao(number, codigo).subscribe(res => {
                    console.log(res);
                    this.counter();  
                    this.codigo = true;
                  });
              }else{
                alert("Não encontramos esse CPF vinculado a essa Matricula, verifique e tente novamente!")
              }
            }else{
              alert("Não encontramos esse CPF vinculado a essa Matricula, verifique e tente novamente!")
            }
          }
        }
      }else{
        alert("Não encontramos esse CPF vinculado a essa Matricula, verifique e tente novamente!")
      }
    })
  }

  counter(){
    this.counterCheck = true;
    this.counterInterval = setInterval(() => {
      let count = this.countdown;
      if(count == 0){
        this.countdown = 59;
        clearInterval(this.counterInterval);
        this.counterCheck = false;
      }else{
        count-- ;
        if(count < 10){
          this.countdown = '0'+count.toString()
        }else{
          this.countdown = count
        }
      }
    }, 1000); 
  } 

  cadastrarNovaSenhaCnpj(){
    this.db.getEmpresaSenha(this.codigoEmpresaSenha,this.cpf).subscribe((data:any) => {
      if(data.length > 0){
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        /* fetch('https://ipinfo.io/json?token=2ccc0f252df3dd').then(response => response.json()).then(ip => {
          console.log(ip)
        }); */
          let dd = {
            senha: this.confirmaNovaSenha,
            ultimoAcesso: {
              data: new Date(Date.now() - 10800000).toISOString().split('T')[0],
              hora:hora
            }
          }
          this.db.patchEmpresa(data[0]._id,dd).subscribe(res => {
            console.log(res);
            let storage = Math.random().toString(36).slice(-8);
            localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
            localStorage.setItem('idUserVM', data[0]._id);
            localStorage.setItem('nomeUsuarioVM', data[0].fantasiaEmpresa);
            localStorage.setItem('codigoEmpresaVM', this.codigoEmpresaSenha);
            localStorage.setItem('tipoUsuarioVM', 'Empresa');
            this._router.navigate(['/empresa']);
          }, err => console.warn(err))
      }else{
        alert("Os dados fornecidos não conferem, por favor entrar em contato com a Viva Max!")
      }
    })
  }

  cadastrarNovaSenha(){
    if(this.matriculaSenha == this.confirmarMatricula){
      let storage = Math.random().toString(36).slice(-8);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      switch(this.tipoAssociado){
        case 'Titular' : {
          let obj = {
            senha: this.novaSenha,
            ultimoAcesso: {
              data: new Date(Date.now() - 10800000).toISOString().split('T')[0],
              hora:hora
            }
          }
          this.db.patchAssociado(this.idUser,obj).subscribe(res => {
            console.log(res);
            localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
            localStorage.setItem('idUserVM', this.idUser);
            localStorage.setItem('nomeUsuarioVM', this.nomeUser);
            localStorage.setItem('tipoUsuarioVM', 'Titular');
            localStorage.setItem('cpfVM', this.cpfUser);
            this.db.nomeUsuario = this.nomeUser;
            this.auth.route.navigate(['/inicio']);
          })
          break;
        }
        case 'Dependente' : {
          let obj = {
            senha: this.novaSenha,
            ultimoAcesso: {
              data: new Date(Date.now() - 10800000).toISOString().split('T')[0],
              hora:hora
            }
          }
          this.db.patchDependenteSenha(this.idUser,this.nomeUser,this.novaSenha,'dependente',obj).subscribe(res => {
            console.log(res);
            localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
            localStorage.setItem('idUserVM', this.idUser);
            localStorage.setItem('nomeUsuarioVM', this.nomeUser);
            localStorage.setItem('tipoUsuarioVM', 'Dependente');
            localStorage.setItem('cpfVM', this.cpfUser);
            this.db.nomeUsuario = this.nomeUser;
            this.auth.route.navigate(['/inicio']);
          })
          break;
        }
        case 'Dependente Extra' : {
          let obj = {
            senha: this.novaSenha,
            ultimoAcesso: {
              data: new Date(Date.now() - 10800000).toISOString().split('T')[0],
              hora:hora
            }
          }
          this.db.patchDependenteSenha(this.idUser,this.nomeUser,this.novaSenha,'dependenteExtra',obj).subscribe(res => {
            console.log(res);
            localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
            localStorage.setItem('idUserVM', this.idUser);
            localStorage.setItem('nomeUsuarioVM', this.nomeUser);
            localStorage.setItem('tipoUsuarioVM', 'Dependente Extra');
            localStorage.setItem('cpfVM', this.cpfUser);
            this.db.nomeUsuario = this.nomeUser;
            this.auth.route.navigate(['/inicio']);
          })
          break;
        }
        default : break;
      }
    }else{
      alert("A Matricula informada esta incorreta, tente novamente ou entre em contato com o Suporte Online!")
    }
  }

  
  cadastrarNovaSenhaServidor(){
    if(this.confirmarMatricula == this.cpfUser){
      let storage = Math.random().toString(36).slice(-8);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      switch(this.tipoAssociado){
        case 'Titular' : {
          let obj = {
            senha: this.novaSenha
          }
          this.db.patchServidor(this.idUser,obj).subscribe(res => {
            console.log(res);
            localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
            localStorage.setItem('idUserVM', "");
            localStorage.setItem('idServidor', this.idUser);
            localStorage.setItem('nomeUsuarioVM', this.nomeUser);
            localStorage.setItem('tipoUsuarioVM', 'Titular');
            localStorage.setItem('cpfVM', this.cpfUser);
            this.db.nomeUsuario = this.nomeUser;
            this.auth.route.navigate(['/servidor']);
          })
          break;
        }
        case 'Dependente Servidor' : {
           let dados = {
              senha: this.novaSenha
            }
            console.log(this.dependenteServidor);
            this.db.patchDependenteSenhaServidor(this.idUser,this.indexServico,this.indexDependente,dados).subscribe(res => {
              console.log(res);
              localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
              localStorage.setItem('idUserVM', "");
              localStorage.setItem('idServidor', this.idUser);
              localStorage.setItem('nomeUsuarioVM', this.nomeUser);
              localStorage.setItem('tipoUsuarioVM', 'Dependente Servidor');
              localStorage.setItem('cpfVM', this.cpfUser);
              this.db.nomeUsuario = this.nomeUser;
              this.db.idServidor = this.idUser;
              this.auth.route.navigate(['/servidor']);
            })
          break;
        }
        default : break;
      }
    }else{
      alert("O CPF informado esta incorreto, tente novamente ou entre em contato com a empresa!")
    }
  }

  openCadastroFuncionario(){
    if(!this.app.isDesktop){
      this.mostrarDireita();
    }
    let modImg = document.getElementById('logo');
    modImg.style.display = 'none';
    let leftCol = document.getElementById('containerLog');
    leftCol.style.display = 'none';
    let logoLogin = document.getElementById('logoLogin');
    logoLogin.style.display = 'block';
    let mod = document.getElementById('firstAccess');
    mod.style.display = 'block';
  }

  closeCadastroFuncionario(){
    this.novoPlanoEmpresa = false;
    this.novoPlanoParticular = false;
    if(!this.app.isDesktop){
      this.mostrarEsquerda();
    }
    let modImg = document.getElementById('logo');
    modImg.style.display = 'flex';
    let leftCol = document.getElementById('containerLog');
    leftCol.style.display = 'block';
    let logoLogin = document.getElementById('logoLogin');
    logoLogin.style.display = 'none';
    let mod = document.getElementById('firstAccess');
    mod.style.display = 'none';
    this.codEmpresa = null;
    this.empresaSelecionada = [];
    this.nomeCompleto = "";
    this.estadoCivil = "";
    this.sexoCadastro = "";
    this.cpfCadastro = "";
    this.nascimento = "";
    this.telefone = "";
    this.celular = "";
    this.funcao = "";
    this.emailCadastro = "";
    this.senhaCadastro = "";
    this.confirmarSenhaCadastro = "";
    this.cep = "";
    this.rua = "";
    this.numero = "";
    this.bairro = "";
    this.complemento = "";
    this.municipio = "";
    this.estado = "";
   // this.ngOnDestroy();
  }

  buscarEmpresa(cod){
    this.db.getEmpresaByCodigo(cod).subscribe((data:any) => {
     if(data.length > 0){
       this.empresaSelecionada = data[0];
      this.stepper.next()
     }else{
       alert("Código de Empresa Inválido, por favor verifique e tente novamente!")
     }
    })
  }

  avancaStep(){
    this.stepper.next()
  }

  voltaStep(){
    this.stepper.previous()
  }
    
  buscarCep(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           alert("CEP NAO ENCONTRADO");
         }else{
           this.rua = cep.logradouro.toUpperCase();
           this.bairro = cep.bairro.toUpperCase();
           this.municipio = cep.localidade.toUpperCase();
           this.estado = cep.uf.toUpperCase();
         }
      });
    }
  }
  
  cpfUnico(cpf){
    this.cpfCheck = true;
    let key = cpf.replace(/\D/g,"");
    if(key.length == 11){
      this.db.getAssociadosCpf(cpf).subscribe((data:any) => {
        if(data.length > 0){
          this.cpfCheck = false;
          alert("ESTE CPF JÁ ESTA CADASTRADO!");
        }else{
          this.cpfCheck = true;
        }
      })
    }
  }

  cadastrarAssociadoAuto(){
    let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    this.db.getConfigs().subscribe((x:any) => {
      let matricula = Number(x[10].matricula);
      let idMatricula = x[10]._id;
      this.db.getAssociadosMatricula(matricula).subscribe((m:any) => {
        if(m && m.length > 0){
          matricula = matricula+10
        }
      let dados = {
        matricula:matricula,
        data: dataHoje,
        hora: hora,
        codigoEmpresa: this.codEmpresa,
        plano : this.empresaSelecionada.plano,
        cpf : this.cpfCadastro,
        estadoCivil : this.estadoCivil,
        nascimento : this.nascimento.split('/').reverse().join('-'),
        nome : this.nomeCompleto,
        sexo: this.sexoCadastro,
        telefone : this.telefone,
        celular : this.celular,
        cep: this.cep,
        rua : this.rua,
        numero : this.numero,
        complemento : this.complemento,
        bairro : this.bairro,
        municipio : this.municipio,
        estado : this.estado,
        email:this.emailCadastro,
        localTrabalho :  this.empresaSelecionada.fantasiaEmpresa,
        funcao : this.funcao,
        doenca : "NÃO",
        especificarDoenca :  "",
        formaPagamento : "EMPRESARIAL",
        mensalidade : Number(this.empresaSelecionada.valorPlano),
        taxaAdesao : 0,
        diaVencimento : this.empresaSelecionada.dataVencimento,
        mesFolhaPagamento : "",
        motivoPendencia : "",
        semAssinatura : false,
        senha:this.senhaCadastro,
        semAutorizacao : false,
        historicos:[{
          "data" : dataHoje, 
          "hora" : hora, 
          "motivo" : "CADASTRO", 
          "msg" : "AUTO CADASTRO NA ÁREA DO CLIENTE", 
          "usuario" : this.nomeCompleto
        }
        ],
        agendamentos:[],
        dataAdesao:dataHoje,
        whatsapp: this.whatsapp,
        dependentes:[],
        dependentesExtra:[],
        nomeCartao : this.nomeCartaoAuto,
        numeroCartao : "",
        validadeCartao : "",
        codigoCartao : "",
        idVendedor: "",
        vendedor:"Sistema",
        totalMensalidade: this.totalMensalidadeAuto,
        subContrato : "",
        planoFisicoCheck: false,
        planoEmpresarialCheck:true,
        cartaoSelecionado: "",
        valorCapitalSegurado: Number(this.empresaSelecionada.valorSeguro),
        coordenador: this.empresaSelecionada.coordenador,
        gerente:this.empresaSelecionada.gerente,
        documentos:[],
        situacao:"ATIVO",
        observacaoPendente : "",
        checkSolicitouCancelamento:false,
        motivoSolicitacaoCancelamento:""
      }
      this.db.cadastroProposta(dados).subscribe(res => {
        console.log(res);
        let idUserCad = res['insertedId'];
        let body = {
          "menu" : "MEU PRIMEIRO ACESSO - AREA DO CLIENTE", 
          "descricao" : "Novo Associado: "+this.nomeCompleto, 
          "data" : dataHoje, 
          "hora" : hora, 
          "usuario" : this.nomeCompleto
        }
        this.db.postLogs(body).subscribe(resLogs => {
          console.log(resLogs);
          let storage = Math.random().toString(36).slice(-8);
          localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
          localStorage.setItem('idUserVM',idUserCad);
          localStorage.setItem('nomeUsuarioVM', this.nomeCompleto);
          localStorage.setItem('tipoUsuarioVM', 'Titular');
          localStorage.setItem('cpfVM', this.cpfCadastro);
          this.db.nomeUsuario = this.nomeCompleto;
          this.db.patchMatricula(idMatricula,{'matricula':matricula+1}).subscribe(res => {
            console.log(res);
              this.closeCadastroFuncionario();
              this._router.navigate(['/inicio']);
              },err => {
                console.log(err);
                alert("ERRO AO CADASTRAR: "+err.message)
            })
        }, err => {
          console.log(err)
        })
      }, err => {
        console.log(err);
        alert("Não foi possivel cadastrar o Associado, por favor tente novamente!");
      });
    });
  });
}

  goToSite(){
    window.open('https://www.vivamax.com.br#planos-todos','_self');
  }

}