import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { AuthService } from '../auth.service';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-servidor',
  templateUrl: './servidor.component.html',
  styleUrls: ['./servidor.component.scss']
})

export class ServidorComponent implements OnInit {

  constructor(public app: AppComponent, public auth:AuthService, public db:DataBaseService) { }

  ngOnInit():void{
    this.db.idServidor = localStorage.getItem('idServidor');
    this.db.tipoUsuario = localStorage.getItem('tipoUsuarioVM');
    if(this.db.idServidor == ""){
      this.auth.logOut();
    }else{
      this.db.idServidor = localStorage.getItem('idServidor');
      this.db.getServidorById(localStorage.getItem('idServidor')).subscribe((dataS:any) =>{
        if(dataS.servicos.length > 0){
          this.db.servicosContratados = [];
          for(let x:any = 0; x < dataS.servicos.length;x++){
            this.db.servicosContratados.push(dataS.servicos[x].servico)
            }
          }
      }) 
    }
  }

}
